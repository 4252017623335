<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-row>
      <v-col cols="6">
        <div
          class="statistics-box d-flex dt-main-info"
          :class="{'blocked-box' : blocked_visibility}"
        >
          <v-col cols="6">
            <label>
              Условия поставки
              <div class="d-flex">
                <v-autocomplete
                  v-model="about_transport_json.delivery_terms_code"
                  :items="deliveryTerms"
                  style="width: 30%;"
                  auto-select-first
                  background-color="grey lighten-2"
                  class="pr-2"
                  item-text="code"
                  item-value="code"
                  dense
                  disabled
                  outlined
                  hide-details="true"
                />
                <v-text-field
                  v-model="about_transport_json.delivery_place"
                  outlined
                  hide-details
                  dense
                  background-color="grey lighten-2"
                  disabled
                />
              </div>
            </label>
          </v-col>
          <v-col
            cols="6"
          >
            <label>
              Расходы на доставку до
              <div class="d-flex">
                <v-text-field
                  v-model="about_transport_json.destination_place"
                  outlined
                  hide-details="auto"
                  dense
                  class="mr-1 p-0 "
                  background-color="white"
                />
              </div>
            </label>
          </v-col>
        </div>
      </v-col>

      <v-col cols="6">
        <div class="statistics-box d-flex">
          <v-col cols="3">
            <label>
              Формы
              <v-text-field
                :value="additionalPages"
                outlined
                hide-details="auto"
                dense
                class="mr-1 p-0 "
                background-color="grey lighten-2"
                disabled
              />
            </label>
          </v-col>
          <v-col
            cols="4"
            offset="2"
          >
            <label>
              А Регистрационный номер
              <field-menu>
                <v-list>
                  <v-list-item
                    @click="disabled = !disabled"
                  >Ручное заполнение</v-list-item>
                  <v-list-item
                    @click="fillWithCustomsData"
                  >Заполнить данными из таможни</v-list-item>
                  <v-list-item
                    @click="clearRegNumA"
                  >Очистить регистрационный номер и сменить ID документа</v-list-item>
                </v-list>
              </field-menu>
              <v-text-field
                ref="reg_num_a"
                v-model="registration_details.reg_num_a"
                v-mask="'#####/######/#######'"
                :background-color="disabled ? 'grey lighten-2' : 'white'"
                :disabled="disabled"
                outlined
                dense
                hide-details="auto"
              />
            </label>
          </v-col>

          <v-col
            cols="3"
          >
            <label>
              Дата
            </label>
            <custom-date-picker
              ref="reg_date_b"
              v-model="registration_details.reg_date_a"
              :background-color="disabled ? 'grey lighten-2' : 'white'"
              :disabled="disabled"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
            />
          </v-col>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import CustomDatePicker from '@/components/shared/custom-date-picker.vue'
import FieldMenu from "@/components/documents/field-menu.vue";
import { eventBus } from "@/bus.js";
import blockVisibility from '@/mixins/block-visibility'
import {type_declaration as onTypeDeclaration} from "@/events/statistics/control";
import {highlightField} from "@/helpers/control";

export default {
  components: {CustomDatePicker, FieldMenu},
  mixins: [blockAutoUpdate, blockVisibility],
  data() {
    return {
      date: null,
      block_id: 'dts-main-info',
      disabled:true,
      // type_declaration: {
      //   declaration_id: null,
      //   customs_procedure: "",
      //   declaration_kind_code: "",
      //   previous_customs_procedure: "",
      // },
      registration_details: {
        declaration_id: null,
        reg_num_a: '',
        // reg_num_b: '',
        reg_date_a: ''
      },
      about_transport: {
        declaration_id: null,
      },
      about_transport_json: {
        delivery_place: '',
        delivery_terms_code: '',
        destination_place: '',
      }
    }
  },
  computed: {
    ...mapGetters({
      selected: 'dts/getSelected',
      selectedWares: "dts/getSelectedWares",
      customsProcedures: "catalogs/getNsiCustomsProcedures",
      deliveryTerms: "catalogs/getNsiDeliveryTerms",
    }),
    quantity() {
      return this.selectedWares?.length || 0;
    },
    additionalPages() {
      return this.quantity <= 1 ? 1 : Math.ceil((this.quantity - 1) / 3) + 1
    },
    filteredProcedures(){
      if(this.type_declaration.declaration_kind_code === 'ЭК'){
        return this.customsProcedures.filter(i => i.direction === 0)
      }else{
        return this.customsProcedures.filter(i => i.direction === 1)
      }
    }
  },
  watch: {
    selected() {
      this.setFields();
    },
  },
  created(){
    onTypeDeclaration.subscribe(this.highlightField)
  },
  beforeDestroy() {
    onTypeDeclaration.unsubscribe()
  },
  methods: {
    highlightField,
    kindChange(){
      this.type_declaration.customs_procedure = null
      eventBus.$emit("clearTransports");
    },
    setFields() {
      // const { type_declaration } = this.selected || {};
      const { registration_details } = this.selected || {};

      this.about_transport.declaration_id = this.selected.about_transport.declaration_id

      const about_transport_json = this.selected.about_transport.about_transport_json

      Object.keys(this.about_transport_json).forEach(key => {
        if(key in about_transport_json){
          this.about_transport_json[key] = about_transport_json[key]
        }
      })

      // for (let key in type_declaration) {
      //   if (key in this.type_declaration) {
      //     this.type_declaration[key] = type_declaration[key];
      //   }
      // }
      for (let key in registration_details) {
        if (key in this.registration_details) {
          this.registration_details[key] = registration_details[key];
        }
      }
      this.setHasChangesFalse();
    },
    isKindOrProcedureChanged(){
      return this.type_declaration.customs_procedure !== this.selected.type_declaration.customs_procedure
          || this.type_declaration.declaration_kind_code !== this.selected.type_declaration.declaration_kind_code
    },
    clearAllPayments(){
      this.$store.dispatch('dts/clearPayments')
        .then(() => this.$info('Очищены платежи'))
        .catch(() => this.$error('Не удалось очистить платежи'))
    },
    async uploadData(){

      // const type_declaration = this.convertEmptyStringsToNull(this.type_declaration);
      const registration_details = this.convertEmptyStringsToNull(this.registration_details)
      const about_transport = this.convertEmptyStringsToNull({
        ...this.about_transport,
        about_transport_json: {
          ...this.about_transport_json
        }
      })



      // const kindOrProcedureChanged = this.isKindOrProcedureChanged()

      // const typeDeclarationResponse = await this.$store.dispatch("dts/uploadBlockData", {
      //   name: "type_declaration",
      //   value: type_declaration,
      // });
      await this.$store.dispatch("dts/uploadBlockData", {
        name: "about_transport",
        value: about_transport,
      });

      await this.$store.dispatch("dts/uploadBlockData", {
        name: "registration_details",
        value: registration_details,
      });

      // if(kindOrProcedureChanged){
      //   this.clearAllPayments()
      // }

      // this.disabled = true
      // return Promise.resolve(typeDeclarationResponse);
    }
  }
}
</script>
